"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSelect = exports.createNumberInput = exports.createElement = void 0;
function createElement(tag, className, content) {
    const e = window.document.createElement(tag);
    className = className || "";
    content = content || "";
    e.className = className;
    if (content !== undefined)
        e.textContent = content;
    return e;
}
exports.createElement = createElement;
function createNumberInput(inputClassName, opts) {
    const wrapper = createElement("div", "input-wrapper"), numInput = createElement("input", inputClassName);
    if (navigator.userAgent.indexOf("MSIE 9.0") === -1) {
        numInput.type = "number";
    }
    else {
        numInput.type = "text";
    }
    if (opts !== undefined)
        for (const key in opts)
            numInput.setAttribute(key, opts[key]);
    wrapper.appendChild(numInput);
    return wrapper;
}
exports.createNumberInput = createNumberInput;
function createSelect(inputClassName, opts) {
    const wrapper = createElement("div", "select-wrapper"), numInput = createElement("select", inputClassName);
    if (opts !== undefined)
        for (const key in opts)
            numInput.setAttribute(key, opts[key]);
    wrapper.appendChild(numInput);
    return wrapper;
}
exports.createSelect = createSelect;
