"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaults = void 0;
exports.defaults = {
    fromCurrency: 'EUR',
    toCurrency: 'USD',
    baseAmount: 100,
    currencyApi: `https://api.exchangeratesapi.io/latest`,
    precision: 4,
    locale: {},
    withText: true
};
